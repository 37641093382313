<script>
import svgIcons from '@/assets/images/svg_icons.svg';
import app from "@/main";

export default {
    data() {
        return {
            isAuth: false,
            burgerOpened: false,
            initialized: false,
            svgIcons: svgIcons,
            serverMenuItems: [],
            menuItems: [
                {
                    name: 'booking',
                    route: 'booking',
                    iconClass: 'icon-ski-pass',
                    icon: 'icon-objects',
                    class: 'pb',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'abonements',
                    route: 'abonements',
                    iconClass: 'icon-card',
                    icon: 'icon-card',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'walking',
                    route: 'walking',
                    iconClass: 'icon-gondola',
                    icon: 'icon-gondola',
                    class: 'pb',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'combo',
                    route: 'combo',
                    iconClass: 'icon-gondola',
                    icon: 'icon-gondola',
                    class: 'pb',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'aquapark',
                    route: 'aquapark',
                    iconClass: 'icon-aqua',
                    icon: 'icon-duck',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'spa',
                    route: 'spa',
                    iconClass: 'icon-spa',
                    icon: 'icon-spa',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'cinema',
                    route: 'cinema',
                    iconClass: 'icon-cinema',
                    icon: 'icon-cinema',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'sprint',
                    route: 'sprint',
                    iconClass: 'icon-sprint',
                    icon: 'icon-sprint',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'events',
                    route: 'events',
                    iconClass: 'icon-cinema',
                    icon: 'icon-events',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'transfer',
                    route: 'transfer',
                    iconClass: 'icon-transfer',
                    icon: 'icon-transfer',
                    class: 'pb',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'parking',
                    route: 'parking',
                    iconClass: 'icon-parking',
                    icon: 'icon-parking',
                    class: '',
                    requiresAuth: false,
                    guestOnly: false,
                },
                {
                    name: 'discounts',
                    route: 'discounts',
                    iconClass: 'icon-voucher',
                    icon: 'icon-voucher',
                    class: '',
                    requiresAuth: true,
                    guestOnly: false,
                },
                {
                    name: 'orders',
                    route: 'orders',
                    iconClass: 'icon-skier',
                    icon: 'icon-skier',
                    class: '',
                    requiresAuth: true,
                    guestOnly: false,
                },
                {
                    name: 'profile',
                    route: 'profile',
                    iconClass: 'icon-skier',
                    icon: 'icon-profile',
                    class: '',
                    requiresAuth: true,
                    guestOnly: false,
                },
                {
                    name: 'login',
                    click: this.login,
                    iconClass: 'icon-login',
                    icon: 'icon-login',
                    class: '',
                    requiresAuth: false,
                    guestOnly: true,
                },
                {
                    name: 'logout',
                    click: this.logout,
                    iconClass: 'icon-logout',
                    icon: 'icon-logout',
                    class: '',
                    requiresAuth: true,
                    guestOnly: false,
                },
            ],
        }
    },
    created() {
        const serverRouters = app.config.globalProperties.$serverRouters;
        serverRouters.forEach(item => {
            let menu = this.menuItems.find(i => i.name === item.tagRoute);
            if (menu) {
                this.serverMenuItems.push({
                    title: item.name,
                    visible: item.visible,
                    enabled: item.enabled,
                    isDebug: item.isDebug,
                    ...menu
                })
            }
        })
    },
    methods: {
        login() {
            this.emitter.emit('showAuthPopup');
        },
        logout() {
            this.emitter.emit("showLogoutPopup");
        }
    },
}
</script>

<template>
    <menu id="main-menu" :class="{open: burgerOpened}">
        <template v-for="item in serverMenuItems">
            <li v-if="item.enabled && item.visible && (!item.isDebug || this.$root.testMode) && ($root.isAuth && !item.guestOnly) || ((!$root.isAuth && !item.requiresAuth) && (!item.isDebug || this.$root.testMode))">
                <router-link v-if="item.route" :to="{name: item.route}" :class="item.class" @click="burgerOpened=false">
                    <svg class="icon" :class="item.iconClass">
                        <use :xlink:href="svgIcons + '#sprite-' + item.icon"></use>
                    </svg>
                    {{ item.title }}
                </router-link>
                <a v-if="item.click" href @click.prevent="item.click" @click="burgerOpened=false">
                    <svg class="icon" :class="item.iconClass">
                        <use :xlink:href="svgIcons + '#sprite-' + item.icon"></use>
                    </svg>
                    {{ item.title }}
                </a>
            </li>
        </template>

    </menu>
    <div id="burger-menu" @click="burgerOpened = !burgerOpened">
        <div class="burger-menu-btn" :class="{open: burgerOpened}">
            <div class="icon"></div>
        </div>
    </div>
</template>

<style scoped>
</style>
