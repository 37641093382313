<script>
import {deleteUrlParam} from "@/hooks/fnHelper";

export default {
    props: {},
    data(){
        return{
            loading: false
        }
    },
    methods: {
        logout() {
            if(!this.loading){
                this.loading = true;

                const params = new URLSearchParams()
                params.append('sid', this.$cookies.get("server_sid"));

                this.axios.post('https://get.polyanaski.ru/getall.dll/postdeauth', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(({data}) => {
                    if (data.error !== 0) {
                        let serverError;
                        switch (data.error) {
                            case 1:
                                serverError = 'Неверный идентификатор сессии';
                                break;
                            case 2:
                                serverError = 'ошибка на стороне БД';
                                break;
                            default:
                                serverError = 'Неизвестная ошибка сервера!';
                                break;
                        }
                        this.$toast.error(serverError);
                    }
                }).finally(async () => {
                    this.$cookies.remove('server_sid', null, this.$root.host);
                    this.$cookies.remove('server_userid', null, this.$root.host);
                    this.$cookies.remove('mrktsid', null, 'ski-pass.polyanaski.ru');
                    this.$cookies.remove("gidClientQrData");
                    this.$cookies.remove("is_gid_client");
                    deleteUrlParam(this.$router, 'send_data', true);
                    this.$root.isAuth = false;
                    this.$root.user = null;

                    try {
                        const {data} = await this.axios.get('https://api.polyanaski.ru/API/');
                        console.log('data', data);
                        if (data?.session?.sid) {
                            this.$cookies.set('server_sid', data.session.sid, null, null, this.host);
                        }
                    }catch (e){}


                    this.$emit('close');
                    setTimeout(() => {
                        this.$router.go();
                    }, 150);

                    this.loading = false;
                });
            }

        }
    }
}
</script>

<template>
    <div class="popup" data-id="logout">
        <div class="popup-container logout">
            <div class="btn-close" @click="$emit('close')">
                <svg class="icon icon-close">
                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-close"></use>
                </svg>
            </div>
            <div class="content">
                <div class="head">
                    <!--                    <svg class="icon icon-info-ex">
                                            <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-info-ex"></use>
                                        </svg>
                                        <span>Вы действительно хотите выйти?</span>-->
                </div>
                <div class="text">
                    <p>Вы действительно хотите выйти?</p>
                </div>
                <div class="buttons">
                    <a href="#" class="btn btn-dark" :class="{loading: loading}" @click.prevent="logout">Выйти</a>
                    <button class="btn btn-close btn-close-white" @click="$emit('close')">Назад</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
