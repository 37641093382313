<script>
export default {

}
</script>

<template>
    <div class="content-box min-h-100vh content-box-i wait-box">
        <div class="wait-content">
            <h3>Сайт временно не доступен (</h3>
        </div>
    </div>

</template>

<style lang="scss"></style>
